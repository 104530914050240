html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevent scrollbars */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Optional: set a background color */
}

.upload-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.header-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.upload-button {
  padding: 10px 20px;
  text-align: center;
  background-color: #002a57;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #0056b3;
}

.custom-upload-button {
  padding: 10px 20px;
  text-align: center;
  background-color: #002a57;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.custom-upload-button:hover {
  background-color: #0056b3;
}

.page-container {
  background-image: url("background.jpeg");
  background-size: cover; /* Make the image cover the entire container */
  background-repeat: no-repeat; /* Prevent tiling of the image */
  background-position: center; /* Center the image */
  min-height: 100vh; /* Ensure it covers the entire viewport height */

  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included within the width and height */
  border: 12px solid #002a57; /* Wider blue border */
  /* border-radius: 8px; Rounded corners */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: space-between;
  align-items: center;
  background-color: white;
  overflow: hidden; /* Prevents scrollbars from appearing */
  position: relative;
}

.page-header {
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  display: block;
}

.page-header h1 {
  font-size: 24px;
  color: #002a57;
  margin: 0;
}

.identifier-input {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.camera-icon-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #002a57;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera-icon-button:hover {
  background-color: #0056b3;
}
